<template>
  <div>
    <WrapperSubHeader>
      <span
        >{{ $t("adminProjects.projects") }} <span class="font-normal text-base">/ {{ $t("adminProjects.edit") }}</span></span
      >
    </WrapperSubHeader>

    <div class="w-full my-5 mt-12">
      <div class="mx-auto container">
        <div class="max-w-xl mx-auto">
          <div v-if="loading" class="w-full mt-5 flex justify-center items-center mx-auto sm:max-w-md px-5 sm:p-0">
            <Loader :onlyLoader="true" />
          </div>
          <div v-if="!loading">
            <!-- Form -->
            <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
              <div class="w-full mx-auto sm:rounded-lg md:max-w-lg mb-7 text-center">
                <h1 class="font-semibold text-lg lg:text-xl mb-3">Editar proyecto ✏</h1>
              </div>
              <Card>
                <template #content>
                  <BlockUI :baseZIndex="100" :autoZIndex="false">
                    <div class="flex flex-col w-full px-2">
                      <form @submit="updateProject" :validation-schema="editProjectSchema">
                        <!-- Company field -->
                        <div class="form-group">
                          <label for="company" class="form-label">{{ $t("register.projectForm.company") }} </label>
                          <InputText v-model="company" id="company" name="company" type="text" class="p-inputtext-sm" />
                        </div>

                        <!-- Name field -->
                        <div class="form-group">
                          <label for="company" class="form-label">{{ $t("adminProjects.name") }} </label>
                          <InputText v-model="name" id="company" name="company" type="text" class="p-inputtext-sm" />
                        </div>

                        <!-- Timezone field -->
                        <div class="form-group">
                          <label for="timezone" class="form-label">{{ $t("register.projectForm.timezone") }} </label>
                          <Dropdown v-model="timeZoneSelect" :options="$store.state.Utilities.timezones" optionLabel="text" dataKey="id" optionValue="id" :filter="true" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                        </div>

                        <!-- Premium field -->
                        <div class="mt-3">
                          <label class="cursor-pointer label px-3 py-3 rounded-md border border-dashed border-gray-300">
                            <span class="label-text text-gray-900 font-semibold text-sm">{{ $t("adminProjects.isPremium") }}</span>
                            <div>
                              <input type="checkbox" class="checkbox checkbox-accent" v-model="is_premium" />
                              <span class="checkbox-mark"></span>
                            </div>
                          </label>
                        </div>

                        <!-- Status field -->
                        <div class="mt-3">
                          <label class="cursor-pointer label px-3 py-3 rounded-md border border-dashed border-gray-300">
                            <span class="label-text text-gray-900 font-semibold text-sm">{{ $t("adminProjects.state") }}</span>
                            <div>
                              <input type="checkbox" class="checkbox checkbox-accent" v-model="is_active" />
                              <span class="checkbox-mark"></span>
                            </div>
                          </label>
                        </div>

                        <!-- Form submit button -->
                        <Button type="submit" :label="$t('adminProjects.modify')" iconPos="right" class="mt-3 p-button-primary p-button-lg w-full" />
                      </form>
                    </div>
                  </BlockUI>
                </template>
              </Card>

              <!--Back button -->
              <router-link :to="{ name: 'Admin Projects' }" class="flex justify-center items-center">
                <Button :label="$t('adminProjects.back')" class="mt-3 p-button-secondary p-button-text" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperSubHeader from "@/components/WrapperSubHeader"
import Loader from "@/components/UI/Loader"
import { useI18n } from "vue-i18n"
import * as yup from "yup"

import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import BlockUI from "primevue/blockui"

export default {
  name: "ProjectEdit",
  props: ["id"],
  components: {
    WrapperSubHeader,
    Loader,

    Card,
    Button,
    InputText,
    Dropdown,
    BlockUI
  },

  data() {
    const { t, locale } = useI18n()
    const editProjectSchema = yup.object({
      name: yup.string().required(),
      company: yup.string().required()
    })

    return {
      t,
      locale,
      editProjectSchema,
      name: "",
      company: "",
      is_active: true,
      loading: false,
      updating: false,
      updateError: false,
      updateErrorMessage: false,
      updateOK: false,
      original: {},
      timeZoneSelect: 57,
      is_premium: false
    }
  },
  methods: {
    updateProject(values) {
      let id = this.id

      this.updateError = false
      this.updateErrorMessage = ""
      this.updateOK = false
      this.updating = true
      values.timezone = this.timeZoneSelect
      values.is_premium = this.is_premium

      this.$store
        .dispatch("AdminProjects/update", { id, ...values })
        .then((response) => {
          if (response.status == 500) {
            this.$toast.error("Ocurrió un error, por favor reintente más tarde")
          }

          if (response.status == 422) {
            this.updateErrorMessage = Object.keys(response.data.errors)
              .map(function (errorKey) {
                return response.data.errors[errorKey].join(",")
              })
              .join(",")
            this.updateError = true
          }

          if (response.status == 204) {
            this.updateOK = true
            this.$toast.success(`Proyecto ${values.name} modificado con éxito`)
          }
          this.updating = false
        })
        .catch((err) => {
          this.$toast.error(err)
          console.log(err)
        })

      if (this.original.is_active != this.is_active) {
        this.$store
          .dispatch("AdminProjects/enabled", { id, is_active: this.is_active })
          .then((response) => {
            if (response.status == 500) {
              this.$toast.error("Ocurrió un error, por favor reintente más tarde")
            }

            if (response.status == 204) {
              this.updateOK = true
              if (this.is_active) {
                this.$toast.success(`Proyecto ${values.name} habilitado con éxito`)
              } else {
                this.$toast.success(`Proyecto ${values.name} deshabilitado con éxito`)
              }
            }
            this.updating = false
          })
          .catch((err) => {
            this.$toast.error(err)
            console.log(err)
          })
      }
    }
  },
  async created() {
    await this.$store.dispatch("Utilities/timezones")
  },
  mounted() {
    this.loading = true
    this.$store.dispatch("AdminProjects/show", this.id).then((response) => {
      if (response.status == 500) {
        this.$toast.error("Ocurrió un error, por favor reintente más tarde")
      }
      if (response.status == 200) {
        this.name = response.data.data.name
        this.company = response.data.data.company
        this.is_active = response.data.data.is_active
        this.original = response.data.data
        this.timeZoneSelect = response.data.data.timezone != null ? response.data.data.timezone.id : null
        this.is_premium = response.data.data.is_premium
      }
      this.loading = false
    })
  }
}
</script>
